import { Strapi4ResponseMany, Strapi4ResponseSingle } from "@nuxtjs/strapi/dist/runtime/types";

export default function useStrapiHelper() {

  function flattenObject<T>(object: Strapi4ResponseSingle<T>) {
    return {
      ...object?.data?.attributes,
      id: object?.data?.id
    }
  }

  function flattenCollection<T>(collection: Strapi4ResponseMany<T>) {
    const data = collection?.data || [];

    return data.map((item) => ({
      ...item.attributes,
      id: item.id
    }));
  }

  return {
    flattenObject,
    flattenCollection
  }

}
